<template>
  <v-container
    id="dashboard-view"
    style="background-color:#E5E5E5;"
    fluid
    tag="section"
  >
    <back class="mt-6" />
    <div
      class="ml-2 font-weight-medium text-h5 greyTxt--text pt-4 mb-2 "
    >
      Pedido
      <span
        class="ml-3 font-weight-negrita text-h6 primary--text"
      >
        #{{ id_pedido || id }}
      </span>
      <span
        v-if="id"
        class="font-weight-regular text-h6 primary--text d-block d-lg-inline-block"
      >
        ( <span class="d-none d-lg-inline-block">Orden de entrega</span> {{ id }})
      </span>
      <span
        v-if="cliente_nombre"
        class="ml-3 font-weight-regular text-subtitle-2 lightGreyTxt--text"
      >
        {{ cliente_nombre }}
      </span>
    </div>

    <v-row>
      <v-col
        class="d-flex align-center justify-end"
        cols="12"
      >
        <app-btn
          v-if="group === 'proveedor'"
          class="mx-2"
          color="sendBtn"
          dark
          @click="generarRemito"
        >
          Generar remito
        </app-btn>
        <app-btn
          color="sendBtn"
          dark
          @click="openRemitos"
        >
          Ver remitos
        </app-btn>
      </v-col>
      <v-col
        v-if="showMsgNoArticulos && !isItems"
        class="d-flex align-center justify-end py-0"
      >
        <span class="error--text">
          Debe seleccionar algún ítem para generar el remito
        </span>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card
          elevation="2"
        >
          <!-- Tabla de Remitos -->
          <generic-table
            :loading="loading || loading_pedidos"
            :headers="headers_pedido"
            :items="pedido_selected"
            show-expand
            is-remito
            :show-select="group === 'proveedor'"
            item-key="id_articulo"
            @changeSelected="changeSelected"
          />
        </v-card>
      </v-col>
      <v-col
        v-if="false"
        cols="2"
        md="4"
        lg="2"
        xl="2"
      >
        <v-card
          elevation="2"
          min-height="100%"
        >
          <div
            class="text-h6 font-weight-medium text-start px-4 py-2"
          >
            Comentarios
          </div>
          <v-divider color="#EEEEEE" />
        </v-card>
      </v-col>
    </v-row>

    <form-modal
      :form="dataToSave"
      :open.sync="dialogBrowse"
      @confirm="generarRemitoConfirm"
    />
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'

  export default {
    name: 'RemitoView',

    data () {
      return {
        dataToSave: false,
        loading: false,
        idArticulos: [],
        dialogBrowse: false,
        showMsgNoArticulos: false,
      }
    },

    computed: {
      group: get('user/group'),
      proveedor: get('user/proveedor'),
      clienteInterno: get('user/clienteInterno'),
      pedido_selected: get('pedidos/pedido_selected'),
      headers_pedido: get('pedidos/headers_detalle_pedido'),
      loading_pedidos: get('pedidos/loading'),
      cliente_nombre () {
        return this.$route.params?.cliente_nombre
      },
      id () {
        return this.$route.params?.id
      },
      id_pedido () {
        return this.$route.params?.id_pedido
      },
      id_proveed () {
        return this.$route?.query?.id_proveed
      },
      isItems () {
        let isItemsLength = false
        this.pedido_selected.map(pedido => {
          const isItem = this.idArticulos.includes(pedido.id_articulo)
          if (isItem) {
            if (this.checkPedidosConItems(pedido).valid) {
              isItemsLength = true
            }
          }
        })

        return this.idArticulos?.length && isItemsLength
      },
    },
    created () {
      this.initialize()
    },
    methods: {
      getPedido: call('pedidos/getPedido'),
      getPedidoCliente: call('pedidos/getPedidoCliente'),
      createRemito: call('remitos/createRemito'),
      initialize () {
        if (this.group === 'proveedor') {
          this.getPedido({ pedido: this.id })
        }
        if (this.group === 'clienteInterno') {
          this.getPedidoCliente({ pedido: this.id })
        }
      },

      checkPedidosConItems (pedido) {
        let isItemsLength = false
        const items = []
        if (pedido.disponible > 0) {
          isItemsLength = true
          items.push(pedido)
        }
        if (pedido.cantReemplazo > 0) {
          pedido.reemplazo.forEach(reemp => {
            if (reemp.disponible > 0) {
              isItemsLength = true
              items.push({ ...pedido, ...reemp })
            }
          })
        }

        return { valid: isItemsLength, items }
      },
      generarRemito () {
        if (this.isItems) {
          this.showMsgNoArticulos = false
          let form = null

          try {
            form = require('@/jsons/remitos/generate-remito.json')
          } catch {
            console.log('No se encontró el json')
          }
          this.dataToSave = form
          this.dialogBrowse = true
        } else {
          this.showMsgNoArticulos = true
          this.dataToSave = false
        }
      },
      async generarRemitoConfirm (response) {
        if (this.isItems) {
          this.loading = true
          let items = []
          this.pedido_selected.map(pedido => {
            const isItem = this.idArticulos.includes(pedido.id_articulo)
            const { valid, items: itemsPush } = this.checkPedidosConItems(pedido)
            if (isItem && valid) {
              items = items.concat(itemsPush)
            }
          })
          await this.createRemito({ proveedor: this.proveedor, items, razonSocial: this.cliente_nombre, fechaEntrega: response.data.fechaEstimada })
          await this.getPedido({ pedido: this.id })
          this.idArticulos = []
          this.dialogBrowse = false
          this.loading = false
        }
      },
      changeSelected (data) {
        this.idArticulos = data
        this.showMsgNoArticulos = false
      },

      openRemitos () {
        if (this.group === 'clienteInterno') {
          this.$router.push(`/remitos/${this.id}/${this.id_pedido}/${this.clienteInterno.name}?id_proveed=${this.id_proveed}`)
        } else {
          this.$router.push(`/remitos/${this.id}/${this.id_pedido}/${this.cliente_nombre}`)
        }
      },
    },
  }
</script>
